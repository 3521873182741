import { useContext, useState, useRef } from "react";

import { Button } from "../Button/Button";
import { PlusIcon } from "../Icons/PlusIcon";
import styles from "./styles.module.scss";
import { AppContext } from "../../App";
import { observer } from "mobx-react-lite";
import { Appear } from "../Appear/Appear";
import { HeroForm } from "./Form/Form";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";

const pathsMap = {
  record: require('../../assets/audio/record.mp3'),
}

export const Hero = observer(() => {
  const { store } = useContext(AppContext);

  return (
    <div className={styles.container}>
      <Appear className={styles.bg} ready={store.mainTransition[3]} direction={"down"}></Appear>
      <div className={styles.center}>
        <Appear className={styles.topSection} ready={store.mainTransition[2]} onFinish={store.mainTransitionNext} direction={"down"}>
          <div className={styles.leftSection}>
            <h1 className={styles.h1}>
              Автоматизация первой линии продаж на базе искусственного интеллекта
            </h1>
            <div className={styles.actionsButtons}>
              <Button size={"small"} onClick={() => store.setContactFormIsOpen(true)}>
                РАССЧИТАТЬ ПРОЕКТ <PlusIcon />
              </Button>
              <Button variant={"tertiary"} size={"small"} onClick={() => store.setContactFormIsOpen(true)}>
                ОСТАВИТЬ ЗАЯВКУ <PlusIcon />
              </Button>
              <AudioPlayer audioSrc={pathsMap.record} />
            </div>
          </div>
          <HeroForm />
        </Appear>
      </div>
    </div>
  );
});
