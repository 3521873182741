export const BurgerIcon = () => {
  return <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_898_104)">
      <rect width="31" height="2" rx="1" fill="#8AFF2F" />
      <rect x="13" y="6" width="18" height="2" rx="1" fill="#8AFF2F" />
    </g>
    <defs>
      <clipPath id="clip0_898_104">
        <rect width="31" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
};