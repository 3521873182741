import { makeAutoObservable } from "mobx";

export class AppStore {
  contactFormIsOpen: boolean = false;
  menuIsOpen: boolean = false;
  playerIsOpen: boolean = false;

  mainTransition: Record<any, boolean> = {
    1: false,
    2: false,
    3: false,
    4: false,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setContactFormIsOpen = (value: boolean) => {
    if (value) {
      this.setMenuIsOpen(false)
    }
    this.contactFormIsOpen = value;
  }

  setMenuIsOpen = (value: boolean) => {
    if (value) {
      this.setContactFormIsOpen(false)
    }
    this.menuIsOpen = value;
  }

  setPlayerIsOpen = (value: boolean) => {
    if (value) {
      this.setMenuIsOpen(false)
      this.setContactFormIsOpen(false)
    }
    this.playerIsOpen = value;
  }

  mainTransitionNext = () => {
    const nextK = Object.keys(this.mainTransition).find(k => this.mainTransition[k] === false) as string;
    console.log('next', nextK)

    this.mainTransition = {
      ...this.mainTransition,
      [nextK]: true
    }
  }
}