import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { AppContext } from "../../../App";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { Button } from "../../Button/Button";
import { SuccessIcon } from "../../Icons/SuccessIcon";
import { useFormik } from "formik";


const FormSchema = Yup.object().shape({
    fullName: Yup.string()
        .required(),
    phone: Yup.string()
        .required(),
    code: Yup.string()
        .optional(),
});


export const HeroForm = observer(() => {
    const { store } = useContext(AppContext)
    const [success, setSuccess] = useState(false)
    const { errors, touched, ...formik } = useFormik({
        initialValues: {
            fullName: '',
            phone: '',
            code: '',
        },
        validationSchema: FormSchema,
        onSubmit: (values: any) => {
            var form = new FormData();

            for (var key in values) {
                form.append(key, values[key]);
            }
            fetch('https://sasha.sale:3001/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setSuccess(true);
                    } else {
                        // Handle error
                        console.error('Error submitting form:', data.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
    });

    const Form = (
        <div className={styles.wrapper}>
            <div className={styles.heading}>
                Получить звонок от Саши
            </div>
            <div className={styles.content}>
                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <input
                        type="text"
                        name="fullName"
                        placeholder="Как тебя зовут?*"
                        onChange={formik.handleChange}
                        value={formik.values.fullName}
                        className={`${styles.formControl} ${errors.fullName && touched.fullName && styles.hasError}`}
                    />
                    <InputMask
                        mask="+7 (999) 999-99-99"
                        name="phone"
                        placeholder="Какой у тебя номер телефона?"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        className={`${styles.formControl} ${styles.formControlPhone} ${errors.phone && touched.phone && styles.hasError}`} />

                    <input type="text" name="code" placeholder="Введи секретный код" onChange={formik.handleChange} value={formik.values.code} className={styles.formControl} />
                    <div className={styles.buttons}>
                        <Button type="submit" variant="secondary" size={'small'} disabled={true}>РАЗГОВОР С САШЕЙ</Button>
                    </div>
                </form>
            </div>
        </div>
    )

    const Success = (
        <div className={styles.success}>
            <SuccessIcon className={styles.successIcon} />
            <div className={styles.heading}>
                Ваша заявка успешно отправлена
            </div>
            <div>
                <p>
                    Пожалуйста, будьте на связи. В скором времени мы с вами свяжемся.
                </p>
            </div>
        </div>
    )

    return <div className={styles.container}>
        <svg width="249" height="243" viewBox="0 0 249 243" fill="none" className={styles.bg}>
            <circle cx="242.5" cy="0.5" r="144.43" stroke="white" stroke-opacity="0.19" />
            <circle cx="242.5" cy="0.500015" r="95.3223" stroke="white" stroke-opacity="0.22" />
            <circle cx="242.5" cy="0.5" r="47" stroke="white" stroke-opacity="0.28" />
            <circle cx="242.499" cy="0.500244" r="193.571" stroke="white" stroke-opacity="0.11" />
            <circle cx="242.5" cy="0.5" r="242" stroke="white" stroke-opacity="0.07" />
        </svg>
        {success ? Success : Form}
    </div>
})
