import styles from './styles.module.scss';


const pathsMap = {
  phone: require('../../assets/images/phone-emoji.png'),
  battery: require('../../assets/images/battery-emoji.png'),
  manFace: require('../../assets/images/man-face-emoji.png'),
  womanFace: require('../../assets/images/woman-face-emoji.png'),
  rocket: require('../../assets/images/rocket-emoji.png'),
  star: require('../../assets/images/star-emoji.png'),
}

interface IEmojiProps {
  name: keyof typeof pathsMap;
  className?: string
}

export function Emoji(props: IEmojiProps) {
  return <div className={`${styles.emoji} ${props.className}`}>
    <div style={{ backgroundImage: `url(${pathsMap[props.name]})` }}></div>
  </div>
}