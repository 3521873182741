import { useContext, useEffect, useLayoutEffect } from 'react';

import { useLockBodyScroll } from '@uidotdev/usehooks';
import { AppContext } from '../../App';
import { Button } from '../Button/Button';
import { LockIcon } from '../Icons/LockIcon';
import styles from './styles.module.scss';
import { PlusIcon } from '../Icons/PlusIcon';
import { Appear } from '../Appear/Appear';
import React from 'react';


const Nav = ({}) => {

  const { store } = useContext(AppContext);
  const [ready, setReady] = React.useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });

  useEffect(() => {
    setReady({ ...ready, 1: true });
  }, []);


  const handleClick = () => {
    const el: any = window.document.querySelector('#features');

    window.scrollTo({ top: el?.offsetTop + window.innerHeight / 2, behavior: 'smooth' });
    store.setMenuIsOpen(false);
  }

  return <nav className={styles.nav}>
    <Appear className={`${styles.navItem} ${styles.navItemLink}`} duration={500} ready={ready[1]} direction={'left'} onFinish={() => setReady({ ...ready, 2: true })} onClick={handleClick}>
      <span>Продукт</span>
    </Appear>
    <Appear className={`${styles.navItem} ${styles.navItemLocked}`} ready={ready[2]} onFinish={() => setReady({ ...ready, 3: true })} duration={500} direction={'left'}>
      <span>Выгоды</span>
      <LockIcon />
    </Appear>
    <Appear className={`${styles.navItem} ${styles.navItemLocked}`} ready={ready[3]} onFinish={() => setReady({ ...ready, 4: true })} duration={500} direction={'left'}>
      <span>Кастомизация</span>
      <LockIcon />
    </Appear>
    <Appear className={`${styles.navItem} ${styles.navItemLocked}`} ready={ready[4]} onFinish={() => setReady({ ...ready, 5: true })} duration={500} direction={'left'}>
      <span>Омниканальность</span>
      <LockIcon />
    </Appear>
    <Appear className={`${styles.navItem} ${styles.navItemLocked}`} ready={ready[5]} onFinish={() => setReady({ ...ready, 6: true })} duration={500} direction={'left'}>
      <span>Безопасность</span>
      <LockIcon />
    </Appear>
    <Appear className={`${styles.navItem} ${styles.navItemLocked}`} ready={ready[6]} duration={500} direction={'left'}>
      <span>Контакты</span>
      <LockIcon />
    </Appear>
  </nav>
}

export const Menu = () => {
  useLockBodyScroll();
  const { store } = useContext(AppContext);

  return <div className={styles.container}>
    <div className={styles.top}>
      <div className={styles.menuLeft}>
        <Nav />
      </div>
      <div className={styles.menuRight}>
        <div className={styles.rightText}>
          sasha.ai <br />sales <br />future is <br />here
        </div>
      </div>
    </div>
    <div className={styles.bottom}>
      <Button onClick={() => store.setContactFormIsOpen(true)}>поговорить с сашей <PlusIcon /></Button>
    </div>
  </div>;
}