import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BottomBlock } from './components/BottomBlock/BottomBlock';
import { Circles } from './components/Circles/Circles';
import { ContactForm } from './components/ContactForm/ContactForm';
import { FeatureBlock } from './components/FeatureBlock/FeatureBlock';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Hero } from './components/Hero/Hero';
import Player from './components/Player/Player';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { AppStore } from './store';

const appContextValue = {
  store: new AppStore(),
}

export const AppContext = React.createContext<typeof appContextValue>(appContextValue);

function App() {
  return (
    <AppContext.Provider value={appContextValue}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Circles />
              <Hero />
              <FeatureBlock />
              <BottomBlock />
              <ContactForm />
              <Player />
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
