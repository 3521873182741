import React from 'react';
import styles from './styles.module.scss';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>Согласие на обработку персональных данных</h1>
      <pre>
        {`1. Общие положения
        Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Вологдин Александр Александрович (далее — Оператор).
        1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
        1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://sasha.sale/.
        1.3. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных настоящая Политика публикуется в свободном доступе в информационно-телекоммуникационной сети Интернет на сайте Оператора.
        2. Основные понятия, используемые в Политике
        2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники;
        2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
        2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://sasha.sale/;
        2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;
        2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;
        2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
        2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
        2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://sasha.sale/;
        2.9. Пользователь — Субъект персональных данных, любой посетитель веб-сайта https://sasha.sale/;
        2.10. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
        2.11. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
        2.12. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;
        2.13. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.
        3. Оператор может обрабатывать следующие персональные данные Пользователя
        3.1. Фамилия, имя, отчество;
        3.2. Электронный адрес;
        3.3. Номера телефонов;
        3.4. Сайт компании;
        3.5. В каком регионе и языке работает компания;
        3.6. Сколько клиентов обрабатывают в месяц;
        3.7. Данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или иной̆ программе, с помощью которой̆ осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой̆ страницы.
        3.8. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
        4. Цели обработки персональных данных
        4.1. Цель обработки персональных данных Пользователя — регистрация Пользователя в базе данных Оператора с последующим направлением Пользователю электронных почтовых сообщений и смс уведомлений, в том числе рекламного содержания, от Оператора, его аффилированных лиц и/или субподрядчиков, информационных и новостных рассылок, приглашений на мероприятия Оператора и другой информации рекламно-новостного содержания, а также с целью подтверждения личности Пользователя при посещении мероприятий Оператора.
        4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты skinnynpale@gmail.com с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».
        4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
        5. Правовые основания обработки персональных данных
        5.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том числе:

        Конституция Российской Федерации;
        Гражданский кодекс Российской Федерации;
        Налоговый кодекс Российской Федерации;
        Федеральный закон от 08.02.1998 N 14-ФЗ «Об обществах с ограниченной ответственностью»;
        Федеральный закон от 06.12.2011 N 402-ФЗ «О бухгалтерском учете»;
        Федеральный закон от 15.12.2001 N 167-ФЗ «Об обязательном пенсионном страховании в Российской Федерации»;
        иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.
        5.2. Правовым основанием обработки персональных данных также являются:

        устав ИП Вологдин Александр Александрович;
        договоры, заключаемые между Оператором и Пользователями;
        согласие субъектов персональных данных на обработку их персональных данных.

        6. Порядок сбора, хранения, передачи и других видов обработки персональных данных
        6.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://sasha.sale/. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
        6.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
        6.3. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
        6.4. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора skinnynpale@gmail.com с пометкой «Актуализация персональных данных».
        6.5. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора skinnynpale@gmail.com с пометкой «Отзыв согласия на обработку персональных данных».
        6.6. Оператор вправе передать персональные данные Пользователя третьим лицам в следующих случаях:
        6.6.1. Пользователь явно выразил свое согласие на такие действия;
        6.6.2. Передача предусмотрена действующим законодательством в рамках установленной процедуры;
        6.6.3. Поручение обработки персональных данных осуществляется на основании заключаемого с этим лицом договора, разработанного с учетом требований Федерального закона РФ от 27 июля 2006 года № 152 «О персональных данных».
        7. Меры по обеспечению безопасности персональных данных
        Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных. Для обеспечения выполнения обязанностей, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных», Оператором принимаются следующие меры и требования к обеспечению безопасности персональных данных:
        7.1. Назначение ответственных работников за организацию обработки персональных данных;
        7.2. Издание документов, определяющих политику Оператора в отношении обработки персональных данных, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений;
        7.3. Обеспечение неограниченного доступа к документу, определяющему политику Оператора в отношении обработки персональных данных, к сведениям о реализуемых требованиях к защите персональных данных;
        7.4. Применение правовых, организационных и технических мер по обеспечению безопасности персональных данных;
        7.5. Осуществление внутреннего контроля и (или) аудита соответствия обработки персональных данных действующему законодательству РФ и принятым в соответствии с ним нормативными правовыми актами, требованиями к защите персональных данных, политике Оператора в отношении обработки персональных данных, локальными актами Оператора;
        7.6. Ознакомление работников Компании, осуществляющих обработку персональных данных, с локальными актами по вопросам обработки персональных данных;
        7.7. Определение актуальных угроз безопасности персональных данных при их обработке в информационных системах персональных данных и разработка мер и мероприятий по защите персональных данных;
        7.8. Установление правил доступа к персональным данным, обрабатываемым в информационных системах персональных данных, а также обеспечение регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных;
        7.9. Применение прошедших в установленном порядке процедуру оценки соответствия средств защиты информации.
        8. Трансграничная передача персональных данных
        8.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.
        8.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.
        9. Заключительные положения
        9.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты skinnynpale@gmail.com.
        9.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
        9.3. Работники Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную ответственность в порядке, установленном федеральными законами.
        9.4. Оператор имеет право вносить изменения в настоящую Политику отношении обработки персональных данных. При внесении изменений в актуальной̆ редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой̆ редакцией̆ Политики.
        9.5. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://sasha.sale/.`}
      </pre>
    </div>
  );
};
