export const CloseIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_898_301)">
      <path d="M4.92893 4.92849L19.0711 19.0706M4.92893 19.0706L19.0711 4.92849" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_898_301">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

};