import styles from './styles.module.scss'


interface IButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  disabled?: boolean;
  children: React.ReactNode;
}

const variants = {
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
  outline: styles.outline,
  plain: styles.plain,
}

const sizes = {
  small: styles.small,
  medium: styles.medium,
}

export function Button({ children, onClick, variant = 'primary', size = 'medium', type = 'button', disabled = false, ...props }: IButtonProps) {
  return (
    <button onClick={onClick} className={`${styles.button} ${variants[variant] ?? ''} ${sizes[size] ?? ''}`} type={type} disabled={disabled} {...props}>
      {children}
    </button>
  );
}
