import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import styles from './styles.module.scss'

interface IAppearProps extends React.ComponentPropsWithoutRef<'div'>{
  children?: React.ReactNode
  className?: string
  ready?: boolean
  onFinish?: () => void
  duration?: number
  direction?: keyof typeof directionsClasses
}

const directionsClasses = {
  up: styles.up,
  right: styles.right,
  down: styles.down,
  left: styles.left,
}

export const Appear = forwardRef(({ children, className, ready = true, onFinish = () => { }, duration = 1000, direction, ...props}: IAppearProps, ref) => {
  const [appear, setAppear] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => {
    return {
      _ref: divRef
    }
  }, [])

  
  useEffect(() => {
    if (ready) {
      setAppear(true);
      const timerId = setTimeout(() => {
        onFinish();
      }, duration);

      return () => clearTimeout(timerId);
    }
  }, [ready]);

  

  return <div
    className={`${className} ${styles.content} ${direction ? directionsClasses[direction] : ''} ${appear && styles.contentAppear} `}
    style={{ transitionDuration: duration ? `${duration}ms` : '0ms' }}
    ref={divRef}
    {...props}
    >
    {children}
  </div>
})