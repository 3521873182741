
import styles from './styles.module.scss'
import YouTube from "react-youtube";
import { Modal } from "../Modal/Modal";
import { observer } from 'mobx-react-lite';
import { CloseIcon } from '../Icons/CloseIcon';
import { useContext } from 'react';
import { AppContext } from '../../App';


export const Player = observer(() => {
  const { store } = useContext(AppContext)
  const options = {
    height: '768',
    width: '1024',
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      modestbranding: 0,
    },
  };

  return store.playerIsOpen ? <Modal handleClose={() => store.setPlayerIsOpen(false)}>
    <div className={styles.container}>
      <div className={styles.close} onClick={() => store.setPlayerIsOpen(false)}>
        <CloseIcon />
      </div>
      <YouTube videoId="AuMV71K9R-o" opts={options} />
    </div>
  </Modal> : null;
})

export default Player;
