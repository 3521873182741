import styles from './styles.module.scss'
import { useEffect, useRef } from 'react';
import { Emoji } from '../Emoji/Emoji';
import { motion, useScroll, useTransform } from 'framer-motion';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

// const container = useRef();


const FeatureSection = ({ children }: { children: React.ReactNode }) => {
  const ref: any = useRef(null);
  // const { scrollYProgress } = useScroll({ target: ref });
  // const y = useTransform(scrollYProgress, [0, 1], [-window.innerHeight / 2, window.innerHeight / 2]);


  useGSAP(() => {
    const tl: any = gsap.timeline({
      scrollTrigger: {
        pin: ref.current?.querySelector('.center'),
        trigger: ref.current,
        start: "center center",
        end: `bottom top`,
        scrub: 1,
        // markers: true,
        id: "scrub"
      }
    })
    tl.from(ref.current?.querySelector('.center'), { opacity: 0, stagger: 1 })

    tl.to(ref.current?.querySelector('.center'), {
      opacity: 1,
      stagger: 1,
      // rotation: 360,
    }) // <-- automatically reverted

    tl.to(ref.current?.querySelector('.center'), {
      opacity: 0,
      stagger: 1,
    }, 1)

    // tl.add(() => {
    //   // if scrolling backward, we need to invert which element fades in or out
    //   const forward = tl.scrollTrigger.direction > 0;
    //         // inEl = forward ? step : steps[i - 1],
    //         // outEl = forward ? steps[i - 1] : step;

    //   if (forward) {
    //     console.log('forward');
    //     gsap.to(ref.current?.querySelector('.center'), {autoAlpha: 0, duration: 0.3, overwrite: true})
    //   } else {
    //     gsap.to(ref.current?.querySelector('.center'), {autoAlpha: 1, duration: 0.3, delay: 0.3, overwrite: true})
    //   }
    // });

  });

  return <div className={styles.content} ref={ref}>
    <div className={'center'}>{children}</div>
    {/* <motion.div style={{ y }}>{children}</motion.div> */}
  </div>

}


export function FeatureBlock() {
  const container = useRef(null);


  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      const container: any = document.querySelector(`.${styles.container}`);
      const containerHeight = container.offsetHeight;
      const containerTop = container.getBoundingClientRect().top;
      const containerBottom = containerTop + containerHeight;
      const scrollTop = window.pageYOffset;
      const scrollBottom = scrollTop + window.innerHeight;

      if (scrollBottom > containerBottom && scrollTop < containerTop) {
        container.classList.add(styles.active);
      } else {
        container.classList.remove(styles.active);
      }

    });
  }, [])

  return <div ref={container} className={styles.container} id="features">
    <div className={styles.shadows} />
    <div className={styles.wrapper} id="featuresWrapper">
      <FeatureSection>
        <>
          <div className={styles.title}>[ О проекте ]</div>
          <div className={styles.text}>
            sasha.ai — первый в России искусственный интеллект, который проводит <Emoji name={'phone'} className={styles.emoji} /> <span> 40-минутные звонки </span> неотличимые от разговора c реальным человеком, <span> на любом языке.</span>
          </div>
        </>
      </FeatureSection>
      <FeatureSection>
        <div className={styles.text}>
          Он автономно коммуницирует <span> со всеми клиентами в CRM</span> <Emoji name={'manFace'} className={styles.emoji} /><Emoji name={'womanFace'} className={styles.emoji} /> и полностью заменяет вашу первую линию продаж
        </div>
      </FeatureSection>
      <FeatureSection>
        <div className={styles.text}>
          Саше не нужны мотивация, контроль или выходные. Он просто работает <Emoji name={'battery'} className={styles.emoji} /> <span>24 часа в день, 7 дней в неделю. </span>
        </div>
      </FeatureSection>
      <FeatureSection>
        <div className={styles.text}>
          <span>Изучает <Emoji name={'star'} className={styles.emoji} /> best-practices</span> в вашем рынке, использует их и закрывает клиентов в целевое действие.
        </div>
      </FeatureSection>
    </div>

  </div>
}
