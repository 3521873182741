import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export function Footer() {
  return <div className={styles.wrapper}>
    <div className={styles.copyright}>
      © Sasha.Ai. {new Date().getFullYear()}
    </div>
    <div className={styles.policy}>
      <Link to="/privacy-policy">Согласие на обработку персональных данных</Link>
    </div>
  </div>;
}
