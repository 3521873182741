
import styles from './styles.module.scss';

import { useLockBodyScroll } from '@uidotdev/usehooks';
import { createPortal } from 'react-dom';
import { useRef } from 'react';
import { Appear } from '../Appear/Appear';

export const Modal = ({ children, handleClose }: any) => {
  useLockBodyScroll()
  const ref = useRef(null);
  return createPortal(
    <div className={styles.modal} onClick={e => e.target === ref.current && handleClose()} ref={ref}>
      <Appear className={styles.modalContent} direction="up">
        {children}
      </Appear>
    </div>, document.body
  );
}