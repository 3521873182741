import React, { useState, useRef } from 'react';
import { Button } from '../Button/Button';
import { PlayIcon } from '../Icons/PlayIcon';
import { PauseIcon } from '../Icons/PauseIcon';

interface AudioPlayerProps {
  audioSrc: string;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <Button variant="outline" size="small" onClick={toggleAudio}>
        {isPlaying ? <PauseIcon /> : <PlayIcon />} {isPlaying ? "остановить" : "слушать пример звонка"}
      </Button>
      <audio ref={audioRef} src={audioSrc} />
    </>
  );
};
